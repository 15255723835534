@import "/fonts/icons.css";

// Here you can add other styles
@mixin right-to-left {
  margin-left: 0;
  flex-direction: row;
  justify-content: flex-start;
  .fa-caret-right {
    display: inline-block;
  }
  .fa-caret-left {
    display: none;
  }
  input {
    float: left;
  }
  i {
    padding-left: 10px;
    padding-right: 0;
  }
  &.latest {
    margin-left: 0;
  }
}

.sidebar .nav-link .nav-icon {
  vertical-align: middle;
  &.cui-dollar, 
  &.cui-settings, 
  &.icon-cloud-upload {
    font-size: 1.15em;
  }
}

.card {
  -webkit-box-shadow: 13px 17px 32px -16px rgba(0,0,0,0.49) !important;
  -moz-box-shadow: 13px 17px 32px -16px rgba(0,0,0,0.49) !important;
  box-shadow: 13px 17px 32px -16px rgba(0,0,0,0.49) !important;
  .is-invalid {
    background-color: #FAF0F0 !important;
  }
}
.aside-menu {
  display: none !important;
}

.cardPayment {
  padding-top: 10px;
  padding-bottom: 10px;
  .rt-noData {
    display: none !important;
  }
}

.topMargin {
  margin-top: 30px;
}

.noTopMargin {
  margin-top: 0 !important;
}

.display.invalid-feedback {
  display: block !important;
}

.billing {
  select.form-control {
    padding: 0.375rem 1.1rem 0.375rem 0.75rem;
    border: 1px solid #c8ced3;
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.cardPayment, .billing {
  .ReactTable .rt-thead .rt-tr .rt-th:first-of-type {
    padding: 15px !important;
  }
}

.billing .ReactTable .rt-tbody .rt-tr-group {
  max-height: inherit !important;
}

.notification-settings {
  .quill {
    width: 100%;
    .ql-toolbar.ql-snow {
      border-top-left-radius: 0.25rem;
    }
    .ql-container.ql-snow {
      border-bottom-left-radius: 0.25rem;
    }

    .ql-container.ql-snow,
    .ql-toolbar.ql-snow {
      border-color: #e4e7ea;
      &:focus {
        color: #5c6873;
        background-color: #fff;
        border-color: #8ad4ee;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.25);
      }
    }

  }

  .is-invalid {
    background-color: #FAF0F0 !important;
    .ql-toolbar.ql-snow {
      background-color: white !important;
      border-bottom-color: #f86c6b;
    }
    .ql-container.ql-snow {
      border-color: #f86c6b;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f86c6b' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
      padding-right: 2.0625rem;
      background-repeat: no-repeat;
      background-size: calc(2.0625rem / 2) calc(2.0625rem / 2);
      background-position: top calc(2.0625rem / 4) right calc(2.0625rem / 4);  
    }
    & ~ .invalid-feedback {
      display: block;
    }
  }

  .is-valid {
    border-color: #4dbd74;
    .ql-toolbar.ql-snow {
      background-color: white !important;
      border-bottom-color: #4dbd74;
    }
    .ql-container.ql-snow {
      border-color: #4dbd74;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%234dbd74' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
      padding-right: 2.0625rem;
      background-repeat: no-repeat;
      background-size: calc(2.0625rem / 2) calc(2.0625rem / 2);
      background-position: top calc(2.0625rem / 4) right calc(2.0625rem / 4);  
    }
  }

  .row:first-child {
    margin-top: 1em;
  }

  @include media-breakpoint-down(sm) {
    .button-card.card:first-child {
      position: relative;
      margin-top: 1.5em !important;
      margin-right: 0;
      border: 0;
      .card-header {
        display: none;
      }
      .card-body {
        display: none;
      }
      .card-footer {
        padding: 0;
        border: 0;
        background: none;
        button {
          width: 100%;
        }
      }
    }
  }

  .card-header-actions {
    height: 24px;
  }
  .switch-label {
    margin-top: 0;
    padding-top: 0;
  }
  .ql-container {
    height: inherit;
  }
  .card {
    margin-top: 1.5rem !important;
  }
  
  .card:first-child {
    margin-top: 0 !important;
  }
}

input,
select,
textarea {
  color: black !important;
}

select {
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 98% 50%;
	
	-webkit-appearance: none;
    /* and then whatever styles you want*/
	height: 30px; 
	width: 100px;
  padding: 5px;
  &:disabled {
    background: none;
  }
}

.card {
  &.fixed {
    position: fixed;
    margin-right: 30px;
  }

  .row.ntp {
    margin-top: 0;
    padding-top: 0;
  }

  .card-body {

    .react-tel-input {
      flex: 1 1 auto;
      width: 10%;
      .form-control {
        width: 100%;
        border-color: #e4e7ea;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        & + .flag-dropdown {
          background-color: #f0f3f5;
          border-color: #e4e7ea;
        }
        &.valid-number {
          @extend .is-valid;
          & + .flag-dropdown {
            border-left-color: #4dbd74;
            border-top-color: #4dbd74;
            border-bottom-color: #4dbd74;
          }
        }
        &.invalid-number {
          @extend .is-invalid;
          & + .flag-dropdown {
            border-left-color: #f86c6b;
            border-top-color: #f86c6b;
            border-bottom-color: #f86c6b;
          }
        }
      }
    }
  }
}

.bg-grey.card-body {
  background-color: #f7f9fa;
}

.nav-tabs .nav-link {
  background-color: #f0f3f5;
  border-color: #c8ced3;
}

.alerts {

  .ladda-button:hover {
    text-decoration: underline !important;
  }

  .ladda-button:focus {
    text-decoration: none;
  }

  .ladda-button:hover,
  .ladda-button:focus,
  .ladda-button {
    background-color: transparent;
    border: none;
  }
}

.alertDetails .ReactTable .rt-thead .rt-tr .rt-th:first-of-type {
  padding: 15px;
}

.notified {
  .icons {
    font-size: 18px;
  }
}
a.nav-link[aria-expanded="true"] {
  color: $red !important;
}

.navbar-nav {
  .dropdown {
    .nav-alerts .dropdown-item {
      padding-top: 0;
      padding-bottom: 5px;
      .avatar {
        height: inherit;
        width: inherit;
      }
      .custom-fire-critic,
      .fa-warning,
      .fa-check {
        display: initial;
        font-size: 22px;
        height: 36px;
        width: 36px;
        margin: 0;
      }
      &.view-all {
        padding-top: 5px;
        padding-bottom: 7px;
      }
    }
  }
}

.alertsCard {
  .avatar {
    height: initial;
    width: initial;
  }
  .badge {
    display: inline-block;
    padding: 0.2em 0.4em 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    margin-top: 10px;
    margin-left: 5px;
  }
}
.app-header .nav-item {
  .fa-chevron-down, 
  .fa-chevron-up {
    font-size: 9px;
    position: relative;
    transform: translate(0%, -30%);
  }
  button {
    cursor: pointer;
  }
  &.acct_nav {
    margin-right: 30px;
    margin-left: 10px;
  }
}

.row {
  margin-top: 22px;
}

.warning {
  color: $yellow !important;
}

.levelIcon {
  font-size: 22px;
}

.critic {
  color: $red !important;
}

.ok {
  color: $green !important;
}

.unread {
  font-weight: bold;
  background: #fff9e5 !important;
}

.unread:hover {
  background: #fff4d3 !important;
}

.card {
  margin-bottom: 0;
}

.rc-calendar-range {
  overflow: inherit !important;
}

.rc-calendar-footer-btn {
  @extend .btn-group;
  .btn-group > .btn:not(:last-child) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;;
  }
}

.rc-calendar-ok-btn-disabled,
.rc-calendar-time-picker-btn-disabled,
.rc-calendar-today-btn-disabled {
  @extend .disabled;
}

.rc-calendar-today-btn,
.rc-calendar-time-picker-btn,
.rc-calendar-ok-btn {
  @extend .btn, .btn-moreoptions;
}

.rc-calendar-time-picker-btn {
  padding: 0.375rem 0.75rem !important;
}

.dropdown-item-checkbox {
  padding: 0 5px 0 0 !important;
}

.react-select__placeholder {
  color: #536c79 !important;
}

.DateRangePicker_picker {
  z-index: 10;
}

.visualize-sensor {
  .tablespinner {
    height: 100%;
  }
}

.react-select__control--is-focused {
  color: #3e515b !important;;
  background-color: #fff;
  border-color: #8ad4ee !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.25) !important;;
}

.react-select__control:hover,
.react-select__control {
  line-height: 1.5;
  color: #3e515b;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #c2cfd6;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.react-select__menu {
  z-index: 10000;
}

.certificateInfo {
  & .success {
    color: $white;
    background-color: $green;
    border-color: $green;
  }
  & .warning {
    background-color: $yellow;
    border-color: $yellow;
  }
  & .danger {
    color: $white;
    background-color: $red;
    border-color: $red;
  }
  .dropdown-toggle {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.no-left-radius {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-moreoptions {
  border: 1px solid #bbb;
  background-image: linear-gradient(to bottom, #fff, #dedede);
  &:hover {
    border: 1px solid #bbb;
    background-image: linear-gradient(to bottom, #fff, #ccc);
  }
}

.visualize-sensor .card-body .input-group div:first-of-type {
  flex: 1 !important;
}

.sidebar .nav-dropdown.open .nav-link.active,
.sidebar .nav-link.active {
  background: #58666C;
  font-weight: bold;
  font-size: 15px;
  color: $yellow;
  i {
    font-weight: bold;
    font-size: 15px;
  }
}

input.form-control[type="text"]:disabled {
  color: $gray-600;
}

/* Safari only */
_:default:not(:root:root), input.form-control[type="text"]:disabled {
  color: $gray-800;
}

span[class*="MuiCheckbox-colorPrimary"][class*="MuiCheckbox-checked"] svg {
  color: $blue !important;
}

span[class*="MuiCheckbox-colorSecondary"][class*="MuiCheckbox-checked"] svg {
  color: $red !important;
}

.ReactTable {
  .rt-tbody .rt-td.checkbox-cell,
  .rt-thead .rt-tr .rt-th:first-of-type {
    padding: 0;
  }

  .header-sortable {
    display: inline-flex;
    align-items: center;
    flex-direction: inherit;
    width: 100%;
    justify-content: space-between;
  }

  .rt-thead {
    .sort-icon {
      width: 16px;
      height: 16px;
      display: none;
      transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
    .-sort-asc .sort-icon {
      transform: rotate(180deg);
      transform-origin: 50% 50%;
    }

    .-sort-asc .sort-icon,
    .-sort-desc .sort-icon {
      display: block;
    }
  }
}

.warning-certificate-links {
  margin-top: 20px;
  border-color: #ffcf33;
  ul {
    margin-bottom: 0;
    padding: 0 20px;
  }
}

.certificate-links {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.asset-description {
  padding-top: 10px;
}

.form-control.autocomplete {
  width: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.sensor-modal-creation {
  max-width: 700px;
}

.hr-text {
  margin-top: 30px;
  margin-bottom: 10px;
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: $blue;
  height: 2em;
  &.first-form-title {
    margin-top: 0;
  }
  &:before {
    content: '';
    background: linear-gradient(to right, $gray-200, $gray-200, $gray-200);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }
  &:after {
    content: attr(data-content);
    border: 1px solid $gray-200;
    position: relative;
    display: inline-block;
    color: $blue;
    font-weight: 700;
    padding: 0 .5em;
    line-height: 1.5em;
    background-color: $white;
    padding: 3px 15px;
  }
}

#preview-textfield {
  display: none;
}

.visualize-sensor {
  .Select-control {
    margin-bottom: 10px;
  }
  .gauge {
    canvas {
      height: 120px;
      padding: 0;
      margin: auto;
      display: block;
    }
  }
  .fixed-height {
    height: 162px;
  }
  .current-value {
    font-size: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.sensor-properties,
.gateway-properties {
  max-width: 990px;
  margin: auto;
  .gauge {
    canvas {
      height: 170px !important;
      width: 340px !important;
      @media (max-width: 1024px) {
        height: 140px !important;
        width: 280px !important;
      }
    }
  }
  .card-footer {
    text-align: right;
  }
}

.sensor-thresholds {
  display: flex;
  justify-content: space-evenly;
  @include media-breakpoint-down(sm) {
    .sidebar-mobile-show & {
      flex-direction: column;
      align-items: center;
    }
  }
  @include media-breakpoint-down(xs) {
    flex-direction: column;
    align-items: center;
  }
  .gauge-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    button {
      margin-bottom: 20px;
      @include media-breakpoint-down(md) {
        margin-bottom: 10px;
      }
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 15px;
    }
  }
  .gauge-setup {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    &.text-field-right {
      margin-left: 115px;
      flex-direction: row-reverse;
      justify-content: flex-end;
      .fa-caret-right {
        display: none;
      }
      input {
        float: right;
      }
      i {
        padding-right: 10px;
      }
      &.latest {
        margin-left: 145px;
      }

      @media screen and (max-width: 1340px) {
          @include right-to-left;
      }
      .modal-body & {
        @include right-to-left;
      }
    }
    input {
      float: left;
      width: 100px;
    }
    span {
      height: 42px;
      width: 20px;
      top: 20px;
      position: relative;
      margin-left: 10px;
    }
    i {
      padding-top: 10px;
      padding-left: 10px;
    }
  }
}

.danger-up,
.danger-down {
  background-color: $red;
}

.warning-up,
.warning-down {
  background-color: $yellow;
}

.success {
  background-color: $green;
}

.rangeslider-horizontal {
  margin: 35px 0;
}

.breadcrumb {
  margin-bottom: 0;
}

.container-fluid {
  > div {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

.app-header.navbar {
  position: fixed;
  z-index: 1020;
  width: 100%;
}

.app-body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 55px;

  .sidebar {
    height: calc(100vh - 55px);
    position: fixed;
    z-index: 1019;
  }
  .main {
    margin-left: 200px;
    margin-right: 250px;
  }
  .aside-menu {
    height: calc(100vh - 55px);
    position: fixed;
    z-index: 1019;
    right: 0;
    .tab-content {
      height: calc(100vh - 2.375rem - 55px);
    }
  }
}

.aside-menu-off-canvas {
  .app-body .main,
  .app-footer {
    margin-right: 0;
  }
}

.sidebar-minimized {
  .app-body .main,
  .app-footer {
    margin-left: 50px;
  }
}

.app-body .main,
.app-footer  {
  margin-left: 0;
  margin-right: 0;
}

@include media-breakpoint-down(md) {
  .sidebar-mobile-show .main {
    margin-right: 0 !important;
  }
}

.css-je5d12, [data-css-je5d12] {
  background-color: $red !important;
  border-radius: 3px;
}

.css-1wc6kks, [data-css-1wc6kks] {
  background-color: $green !important;
  border-radius: 3px;
}

.card-header-actions {
    display: inline-block;
    margin-right: -.25rem;
    .btn-link i:before {
      color: $gray-900;
    }
}

.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
  border: 1px solid $gray-200;
}

.ReactTable .rt-thead.-filters select {
  height: 29px;
}

.tablespinner {
  display: flex;
  height: calc(100vh - 300px);
  text-align: center;
  justify-content: center;
  align-items: center;
}

.cardPayment {
  .tablespinner {
    height: 100px;
  }
  .cardNumber {
    white-space: inherit !important;
  }
  .payment-card-icon {
    width: 30px;
  }
}

.ReactTable .rt-tbody .rt-tr-group {
  max-height: 50px;
}

.rt-tbody {
  z-index: 1001;
}

.-filters .rt-tr {
  visibility: hidden;
  opacity: 0;
}

.rt-thead.-filters {
  -moz-transition: height .5s;
  -ms-transition: height .5s;
  -o-transition: height .5s;
  -webkit-transition: height .5s;
  transition: height .5s;
  height: 0;
}

.card-header {
  .btn {
    margin-top: inherit;
  }
}

.sensorListCard,
.gatewayListCard,
.listCard {
  .card-header {
    .card-header-actions {
      margin-right: inherit;
      .btn {
        margin-top: inherit;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .aside-menu {
    display: none;
  }
  .app-body .main,
  .app-footer {
    margin-right: 0;
    .sidebar-mobile-show & {
      margin-left: 220px !important;
    }
  }
}

@include media-breakpoint-down(md) {
  .btn-label-list {
    display: none;
  }
}

@include media-breakpoint-down(xs) {
  .sidebar-mobile-show {
    overflow: hidden;
    .app-body .main,
    .app-footer {
      margin-left: 0 !important;
    }
    .app-footer {
      //overflow: hidden;
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1018;
        width: 100%;
        height: 100%;
        content: "";
        background: rgba(0,0,0,.7);
        -webkit-animation: opacity .25s;
        animation: opacity .25s;
      }
    }
  }
}

label {
  display: block;
  margin: 5px 0;
}

code {
  padding: .2em .5em;
  font-size: 85%;
  background-color: rgba(0,0,0,0.04);
  border-radius: 3px;
}

.menu {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #cccccc;
  z-index: 99999;
  background: white;
}

.item {
  padding: 2px 6px;
  cursor: default;
}

.item-highlighted {
  color: white;
  background-color: #4095bf;
}

.item-header {
  background-color: #eeeeee;
  color: #454545;
  font-weight: bold;
}
